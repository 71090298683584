<template>
	<el-container class="home-container">
		<el-aside width="250px">
			<div class="lefttop-wrap">
				<p>休斯顿好房网</p>
				<div class="name-wrap" @click="jumpToWelcome">
					<div class="avatar"></div>
					<div :style="nameStyle">{{username}}<br><span :title="email">{{email}}</span></div>
				</div>
				<el-menu :default-active="activePath" class="el-menu-top" mode="vertical"
					background-color="#333333" text-color="#f2f2f2" active-text-color="#F2994A" router>
					<el-menu-item index="/income" style="padding: 0">
						<i class="iconfont icon-shouyi"></i>
						<span slot="title" class="title">查看收益</span>
					</el-menu-item>
					<el-menu-item index="/invoice" style="padding: 0">
						<i class="iconfont icon-yunduanxiazai"></i>
						<span slot="title">
							<el-badge v-if="unreadCount !== 0" :value="unreadCount" :max="10" class="item home">
								下载Invoice</el-badge>
							<span v-else>下载Invoice</span>
						</span>
					</el-menu-item>
					<el-menu-item index="/details" style="padding: 0">
						<i class="iconfont icon-debtslip"></i>
						<span slot="title">欠款详情</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="leftbottom-wrap">
				<el-menu :default-active="activePath" @select="handleSelect" class="el-menu-bottom"
					mode="vertical" background-color="#333333" text-color="#f2f2f2"
					active-text-color="#F2994A">
					<el-menu-item index="/contact" style="padding: 0">
						<i class="iconfont icon-shouye"></i>
						<span slot="title" class="title">联系GIGI</span>
					</el-menu-item>
					<el-menu-item index="2" style="padding: 0">
						<i class="iconfont icon-tuichu"></i>
						<span slot="title">登出</span>
					</el-menu-item>
				</el-menu>
			</div>
		</el-aside>
		<el-main>
			<router-view></router-view>
		</el-main>
	</el-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
	name: 'Home',
	data() {
		return {
			username: window.sessionStorage.getItem('username'),
			email: window.sessionStorage.getItem('email'),
			activePath: window.sessionStorage.getItem('activePath') || '/welcome',
			nameStyle: this.$route.path === '/welcome' ? {color: '#f2994a'} : {},
		};
	},
	computed: {
		...mapState(['unreadCount'])
	},
	created() {
		this.getUnreadCount();
	},
	mounted() {
		if (!window.sessionStorage.getItem('timeCount')) {
			window.sessionStorage.setItem('timeCount', 0);
		}
		const timeCount = parseInt(window.sessionStorage.getItem('timeCount'));
		if (timeCount > 0) {
			this.changeButtonControl({isDisable: true, timeCountDown: timeCount});
			this.setButtonControlValue();
		}
	},
	methods: {
		...mapActions(['setButtonControlValue']),
		...mapMutations(['changeUnreadCount', 'changeButtonControl']),
		async getUnreadCount() {
			const {data: res} = await this.$http.get('client/invoiceUnread/' + window.sessionStorage.getItem('cid'));
			if (res.meta.status !== 200) return this.$message.error('获取未读Invoice数量失败！');
			this.changeUnreadCount(res.data);
		},
		logout() {
			window.sessionStorage.clear();
			this.$router.push('/login');
		},
		saveNavState(activePath) {
			window.sessionStorage.setItem('activePath', activePath);
			this.activePath = activePath;
		},
		handleSelect(key) {
			if (key === '/contact' && key !== this.$route.path) {
				this.$router.push(key);
			}
			if (key === '2') {
				this.$confirm('是否退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '退出成功'
					});
					this.logout();
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消退出'
					});
				});
			}
		},
		jumpToWelcome() {
			if (this.$route.path !== '/welcome') this.$router.push('/welcome');
			else return;
		}
	},
	watch: {
		'$route'(to) {
			const pathArr = to.path.split('/');
			this.saveNavState('/' + pathArr[1]);
			if (pathArr[1] === 'welcome') this.nameStyle = {color: '#f2994a'};
			else this.nameStyle = {};
		}
	}
}
</script>

<style lang="less" scoped>
.home-container {
	position: relative;
	height: 100%;
	.el-aside {
		position: absolute;
		background-color: #333333;
		height: 100%;
		.lefttop-wrap {
			width: 202px;
			margin: 48px auto 0;
			border-bottom: 1px solid #f2f2f2;
			padding-bottom: 40px;
			p {
				font-size: 18px;
				line-height: 1.4;
				color: #f2994a;
				text-align: center;
				margin-bottom: 40px;
			}
			.name-wrap {
				display: flex;
				align-items: center;
				cursor: pointer;
				.avatar {
					border-radius: 50%;
					flex: 0 0 45px;
					height: 45px;
					background: url('~@/assets/img/avatar.png') no-repeat center;
					background-color: #4f4f4f;
					margin-right: 17px;
				}
				div {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					color: #f2f2f2;
					font-size: 14px;
					line-height: 1.4;
					span {
						font-size: 12px;
					}
				}
			}
			.el-menu {
				margin-top: 40px;
				border-right: none;
				.el-menu-item {
					position: relative;
					.iconfont {
						font-size: 18px;
						padding-right: 22px;
					}
					&::after {
						position: absolute;
						content: '';
						right: 20px;
						top: 50%;
						width: 8px;
						height: 8px;
						border-top: 1px solid;
						border-right: 1px solid;
						transform: rotate(45deg) translateY(-50%);
					}
				}
			}
		}
		.leftbottom-wrap {
			position: absolute;
			width: 202px;
			left: 50%;
			bottom: 72px;
			transform: translateX(-50%);
			.el-menu {
				margin-top: 40px;
				border-right: none;
				.el-menu-item {
					position: relative;
					.iconfont {
						font-size: 18px;
						padding-right: 22px;
					}
					&::after {
						position: absolute;
						content: '';
						right: 20px;
						top: 50%;
						width: 8px;
						height: 8px;
						border-top: 1px solid;
						border-right: 1px solid;
						transform: rotate(45deg) translateY(-50%);
					}
				}
			}
		}
	}
	.el-main {
		position: absolute;
		left: 250px;
		top: 0;
		right: 0;
		bottom: 0;
		overflow-y: scroll;
		background-color: #e5e5e5;
	}
}
</style>